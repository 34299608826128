import { useQuery } from '@tanstack/react-query';
import { Button, DatePicker, Form, Input, InputNumber, Select, TableProps } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CustomModal } from '@/components/Common/CustomModal';
import { get_reasons_list } from '@/services/adjustments/queries';
import AdjustmentResuableView from '../../../adjustments/view';
import { CustomDatePresets } from '../utils/datePresets';
import CustomReport from './report';
import { SorterResult } from 'antd/lib/table/interface';
import { nepaliNumberFormatter } from '@/utils/numberFormatter';
import {
  convert_nepali_to_english_date,
  convert_string_to_nepali_date_string
} from '@/utils/nepaliDateConverter';
import { find_date_preference, find_to_use_NPR } from '@/store/localstorage/preferences';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { getUserData } from '@/utils/auth.utils';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import { DEFAULT_DATE_FORMAT } from '@/constants';
const { Option } = Select;
const { RangePicker } = DatePicker;

const StockAdjustmentReport = () => {
  const [form] = Form.useForm();
  const { data: reasonList } = useQuery(['reasons'], async () => get_reasons_list());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };

  const [nepaliType] = useState(find_date_preference());
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  useEffect(() => {
    const nepaliDateStart = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    const nepaliDateEnd = convert_string_to_nepali_date_string(
      CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm')
    );
    form.setFieldValue('constraints', {
      page: 1,
      size: 100,
      locationId: preferenceLocationId ? preferenceLocationId : '',
      reasonId: '',
      dateCustom: [...CustomDatePresets.Today],
      startDate: CustomDatePresets.Today[0].format('YYYY-MM-DD HH:mm'),
      endDate: CustomDatePresets.Today[1].format('YYYY-MM-DD HH:mm'),
      startDateNepali: nepaliDateStart,
      endDateNepali: nepaliDateEnd
    });
  }, []);

  const breadcrumbItems = [
    { label: 'Reports', link: '/reports' },
    { label: 'Stock Adjustment', link: '/reports/stock-adjustment' }
  ];

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dateStrings.length == 2) {
      form.setFieldValue(['constraints', 'startDate'], dateStrings[0]);
      form.setFieldValue(['constraints', 'endDate'], dateStrings[1]);
    }
  };
  const columns: ColumnsType<any> = [
    {
      title: 'S.N',
      key: 'SN',
      width: 30,
      // sorter: (a, b) => a.id - b.id,
      // sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record, index) => {
        return <div color="black">{index + 1}</div>;
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Button
          type="primary"
          onClick={() => {
            setIsModalOpen(true);
            setId(record.id);
          }}>
          View
        </Button>
      ),
      width: 70
    },
    // {
    //   title: 'ID',
    //   key: 'id',
    //   render: (record) => (
    //     // <Link to={`/adjustments/${record.id}`} color="black">
    //     <div>{record.id}</div>
    //     // </Link>
    //   )
    // },
    {
      title: 'Date',
      // dataIndex: 'adjustment_date',
      // render: (text: string) => <div>{moment(text).local().format(DEFAULT_DATE_FORMAT)}</div>,
      key: 'adjustment_date',
      sorter: (a, b) => moment(a.adjustment_date).unix() - moment(b.adjustment_date).unix(),
      sortOrder: sortedInfo.columnKey === 'adjustment_date' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{moment(record.adjustment_date).local().format(DEFAULT_DATE_FORMAT)}</div>;
      },
      width: 120
    },
    {
      title: 'Reference No.',
      // dataIndex: 'reference_number',
      // render: (text: string) => <div>{text}</div>,
      key: 'reference_number',
      className: 'invoice',
      sorter: (a, b) => a.reference_number.localeCompare(b.reference_number),
      sortOrder: sortedInfo.columnKey === 'reference_number' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.reference_number}</div>;
      },
      width: 120
    },
    {
      title: 'Location',
      sorter: (a, b) => a.location_name.localeCompare(b.location_name),
      sortOrder: sortedInfo.columnKey === 'location_name' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.location_name}</div>;
      },
      width: 100
    },
    {
      title: 'Adjustment Type',
      key: 'adjustmentType',
      // render: (text: string) => <div>{text}</div>,
      sorter: (a, b) => a.adjustmentType.localeCompare(b.adjustmentType),
      sortOrder: sortedInfo.columnKey === 'adjustmentType' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/locations/${record.locationId}`}>
          <div>{record.adjustmentType}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Total Amount',
      // dataIndex: 'total_amount',
      // render: (text: string) => <div>{text}</div>,
      key: 'total_amount',
      className: 'highlight',
      sorter: (a, b) => a.total_amount - b.total_amount,
      sortOrder: sortedInfo.columnKey === 'total_amount' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">{nepaliNumberFormatter(record.total_amount)}</div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Total Amount Recovered',
      // dataIndex: 'totalAmountRecovered',
      // render: (text: string) => <div>{text}</div>,
      key: 'totalAmountRecovered',
      sorter: (a, b) => a.totalAmountRecovered - b.totalAmountRecovered,
      sortOrder: sortedInfo.columnKey === 'totalAmountRecovered' ? sortedInfo.order : null,
      render: (record) => {
        return (
          // <Link to={`/sell/${record.id}`} color="black">
          <div className="text-right mr-2">
            {record.totalAmountRecovered && nepaliNumberFormatter(record.totalAmountRecovered)}
          </div>
          // </Link>
        );
      },
      width: 100
    },
    {
      title: 'Reason',
      key: 'reason',
      sorter: (a, b) => a.reason.localeCompare(b.reason),
      sortOrder: sortedInfo.columnKey === 'reason' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.reason}</div>;
      },

      width: 100
    },
    {
      title: 'Added By',
      key: 'user',
      sorter: (a, b) => a.user.localeCompare(b.user),
      sortOrder: sortedInfo.columnKey === 'user' ? sortedInfo.order : null,
      render: (record) => {
        return <div>{record.user}</div>;
      },
      width: 100
    }
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };

  const onChangeNepali = (val: string, isStart: boolean) => {
    if (isStart)
      form.setFieldValue(['constraints', 'startDate'], convert_nepali_to_english_date(val));
    else form.setFieldValue(['constraints', 'endDate'], convert_nepali_to_english_date(val));
  };

  return (
    <CustomReport
      toSort={handleChange}
      breadcrumbItems={breadcrumbItems}
      state={{ id: 15, name: 'Stock Adjustment Report' }}
      columnsData={columns}
      form={form}
      toDownload={true}>
      <CustomModal
        isModalOpen={isModalOpen}
        footer={false}
        setIsModalOpen={setIsModalOpen}
        title={'Adjustment View'}>
        <AdjustmentResuableView id={id} />
      </CustomModal>
      <Form.Item label={'page'} name={['constraints', 'page']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <Form.Item label={'size'} name={['constraints', 'size']} hidden={true}>
        <InputNumber controls={false} />
      </Form.Item>
      <LocationSearchV2 showAll hasParentFormItem={false} name={['constraints', 'locationId']} />
      {/* <Form.Item name={['constraints', 'locationId']} label="Location"> */}
      {/* <Select placeholder="Select a location!">
          <Option value="">All</Option>

          {locationsList?.data.results.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select> */}
      {/* <LocationSearch withoutForm={true} onSelect={(val) => onSelectt(val)} /> */}
      {/* </Form.Item> */}
      <Form.Item name={['constraints', 'reasonId']} label="Reason">
        <Select placeholder="Select a reason!">
          <Option value="">All</Option>

          {reasonList?.data.map((value: any) => {
            return (
              <Option value={value.id} key={value.id}>
                {value.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label={'Pick a date'}
        name={['constraints', 'dateCustom']}
        rules={[
          {
            required: true,
            message: 'Please select date!'
          }
        ]}
        hidden={nepaliType}>
        <RangePicker
          showTime
          ranges={CustomDatePresets}
          disabledDate={disabledDate}
          onChange={onChange}
          format={'YYYY-MM-DD HH:mm'}
          allowClear={false}
          className="w-full"
        />
      </Form.Item>
      <Form.Item label={'Start Date'} name={['constraints', 'startDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'End Date'} name={['constraints', 'endDate']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label={'Select Start Date'} name={['startDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, true)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
      <Form.Item label={'Select End Date'} name={['endDateNepali']} hidden={!nepaliType}>
        <NepaliDatePicker
          onChange={(val) => onChangeNepali(val, false)}
          options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
      </Form.Item>
    </CustomReport>
  );
};

export default StockAdjustmentReport;
