import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Input, Menu, Form, Select, TableProps, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';

import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import { get_vendor_list_ids } from '@/services/users/queries';

import { get_location_list } from '@/services/locations/queries';
import CustomButton from '@/components/Common/CustomButton/CustomButton';

import VendorsDB from '@/store/localstorage/VendorDB';
import { ConvertObjectToURL } from '@/utils/converturl';
import { SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '@/utils/auth.utils';
import DeleteConfirmModal from '@/components/Common/Deleteconfirm/DeleteConfirmModal';
import { delete_vendor_products } from '@/services/vendor-products/mutation';
import { get_vendor_products_list } from '@/services/vendor-products/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { get_product_list_ids } from '@/services/products/queries';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import ActionDropdown from '@/components/Common/Dropdownactions';
import { ExportColumnType, exportExcel } from '@/utils/exportExcel';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { IVendorProductListResponse } from '@/services/vendor-products/types';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import CustomViewIcon from '@/components/Common/CustomIcons/CustomViewIcon';
import { CustomDatePresets } from '../../sqlsource/report/utils/datePresets';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import VendorSearchV2 from '@/components/Common/CustomSearch/Vendors';

const VendorProductsList: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(true);
  // const [allpurchaseList, setPurchaselist] = useState<IpurchaseList[]>([]);
  const [allVendorList, setAllVendorList] = useState<IVendorProductListResponse>({
    total: 0,
    vendorProducts: []
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  const onSubmitFilter = async (val: string) => {
    await getInfo(val);
    setPage(1);
    setSize(10);
  };
  const getInfo = async (filter = '') => {
    setIsloading(true);
    const response = await get_vendor_products_list(filter);
    const searchUsers: any = {};
    const searchProducts: any = {};
    for (let index = 0; index < response.data.vendorProducts.length; index++) {
      const user: any = await VendorsDB.getVendors(response.data.vendorProducts[index].vendorId);
      if (!user) {
        if (response.data.vendorProducts[index].vendorId in searchUsers) {
          searchUsers[response.data.vendorProducts[index].vendorId] = [
            ...searchUsers[response.data.vendorProducts[index].vendorId],
            index
          ];
        } else {
          searchUsers[response.data.vendorProducts[index].vendorId] = [index];
        }
      } else {
        response.data.vendorProducts[index].userName = user.user.name;
        response.data.vendorProducts[index].userData = user;
      }
      const product: any = await ProductsDB.getProduct(
        response.data.vendorProducts[index].productId
      );
      if (!product) {
        // product = await get_product_details(linesResponse.lines[index].productId);
        // await ProductsDB.addProducts([product]);
        if (response.data.vendorProducts[index].productId in searchProducts) {
          searchProducts[response.data.vendorProducts[index].productId] = [
            ...searchProducts[response.data.vendorProducts[index].productId],
            index
          ];
        } else {
          searchProducts[response.data.vendorProducts[index].productId] = [index];
        }
      } else {
        response.data.vendorProducts[index].productName = product.name;
      }
    }
    const searchUserslength = Object.entries(searchUsers).length;
    if (searchUserslength > 0) {
      const customerresponse = await get_vendor_list_ids([...Object.keys(searchUsers)]);
      for (const key in searchUsers) {
        const vendorUser = customerresponse?.data?.results.find(
          (currCustomer: any) => currCustomer.id == key
        );
        if (vendorUser) {
          for (let i = 0; i < searchUsers[key].length; i++) {
            response.data.vendorProducts[searchUsers[key][i]].userName = vendorUser?.user.name;
            response.data.vendorProducts[searchUsers[key][i]].userData = vendorUser;
          }
          await VendorsDB.addVendors([vendorUser]);
        }
      }
    }

    const searchProductslength = Object.entries(searchProducts).length;
    if (searchProductslength > 0) {
      const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
      for (const key in searchProducts) {
        const findproduct = productsresponse?.data?.results.find(
          (currProduct: any) => currProduct.id == key
        );
        if (findproduct) {
          for (let i = 0; i < searchProducts[key].length; i++) {
            response.data.vendorProducts[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
    }
    // console.log('Response data-->', response.data);
    // setSellList(response.data.results);
    setAllVendorList(response.data);
    setIsloading(false);
  };

  const deleteofferMutation = useMutation(delete_vendor_products, {
    onSuccess: () => {
      message.success('Product deleted successfully');
      const values = form.getFieldsValue();
      values.endDate = convertLocalToUTCString(values.endDate);
      values.startDate = convertLocalToUTCString(values.startDate);
      delete values.dateCustom;
      delete values.startDateNepali;
      delete values.endDateNepali;
      onSubmitFilter(ConvertObjectToURL(values));
      // getInfo();
      // dispatch(removeLocation(selectedID));
    }
  });
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      key: 'id',
      width: 5,
      render: (record) => {
        return <TableCell>{record.id}</TableCell>;
      }
    },
    {
      title: 'Vendor',
      key: 'vendorId',
      width: 15,
      render: (record) => {
        return <TableCell>{record.userName}</TableCell>;
      }
    },
    {
      title: 'Product',
      key: 'productId',
      width: 15,
      render: (record) => {
        return <TableCell>{record.productName}</TableCell>;
      }
    },

    {
      title: 'Actions',
      key: 'actions',
      width: 3,
      fixed: 'right',
      render: (record) => {
        const confirmFunction = () => {
          deleteofferMutation.mutate(record.id);
        };
        const menuItems: (
          | {
              key: string;
              label: JSX.Element;
              onClick?: undefined;
            }
          | {
              key: string;
              label: JSX.Element;
              onClick: () => void;
            }
        )[] = [
          {
            key: '1',
            label: <CustomUpdateIcon link={`/vendor-products/${record.id}`} />
          },
          {
            key: '2',
            label: <DeleteConfirmModal FunctionAfterOk={confirmFunction} />
          },
          {
            key: '3',
            label: <CustomViewIcon link={`/vendor-products/view/${record.id}`} />
          }
        ];

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  const breadcrumbItems = [
    {
      label: 'Vendor Products',
      link: '/vendor-products'
    }
  ];
  const { Option } = Select;
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());

  const onPagination = async (pageNo = 1, totalSize = 10, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    delete values.dateCustom;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    // const response = await get_purchase_list_filter(url);
    // setallPurchaseList(response.data);
    getInfo(url);
  };

  const handleExport = () => {
    setIsloading(true);
    try {
      const columns: ExportColumnType[] = [
        {
          title: 'S.N',
          dataIndex: 'SN',
          width: 50
        },
        {
          title: 'Vendor',
          dataIndex: 'userName',
          width: 300
        },
        {
          title: 'Product',
          dataIndex: 'productName',
          width: 300
        }
      ];
      if (allVendorList.vendorProducts.length === 0) {
        message.error('No Data to Export');
        return;
      }
      const dataUpdated = allVendorList.vendorProducts.map((item: any, index: number) => {
        return {
          ...item,
          SN: index + 1
        };
      });
      exportExcel(columns, dataUpdated, 'Vendor Product List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              {locationsList && (
                <TableFilter
                  defaultValues={{
                    dateCustom: [...CustomDatePresets.Today],
                    value: '',
                    paymentStatus: '',
                    purchaseStatus: '',
                    supplier: '',
                    locationId: preferenceLocationId ? preferenceLocationId : '',
                    vendorId: ''
                  }}
                  initial={true}
                  onSubmit={onSubmitFilter}
                  form={form}
                  style={
                    'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                  }
                  styleforbuttons="flex justify-end items-center"
                  buttons={
                    <>
                      <CustomButton
                        onClick={() => navigate(`/vendor-products/new`)}
                        text="Add"
                        backgroundColor="#1890ff"
                        // marginTop="17px"
                        Linkto="/vendor-products/new"
                      />

                      <CustomButton
                        text="Export"
                        backgroundColor="#1890ff"
                        onClick={handleExport}
                      />
                    </>
                  }>
                  <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                  <VendorSearchV2 hasParentFormItem={false} name={'vendorId'} showAll />
                  <Form.Item name="value" label="Search">
                    <Input placeholder="Search" />
                  </Form.Item>
                </TableFilter>
              )}
            </div>
            {/* </div> */}
          </>
        }>
        <CustomizeTable
          columns={columns}
          data={allVendorList.vendorProducts}
          usersLoading={isLoading}
          notshowPagination={true}
          // toshowcustomPagintion={true}
          // onPagination={onPagination}
          paginationDatas={{
            page: page,
            total: allVendorList.total,
            size: size,
            onPagination
          }}
          tableName={'vendor-products-list'}
          toSort={handleChange}
        />
      </AppContent>
    </div>
  );
};

export default VendorProductsList;
