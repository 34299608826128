import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Select, message, PageHeader, Input, DatePicker, Collapse } from 'antd';
import moment from 'moment';
// import { useWatch } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AppContent from '../../../components/Common/Content/Content';
import CustomButton from '../../../components/Common/CustomButton/CustomButton';
import CustomSubmitButton from '../../../components/Common/CustomButton/CustomSubmitButton';
import { ProductsSearch } from '../../../components/Common/ProductsSearch';
import { VendorSearch } from '../../../components/Common/VendorSearch/VendorSearch';
import { get_vendor_details } from '../../../services/users/queries';
import {
  create_vendor_products,
  update_vendor_products
} from '../../../services/vendor-products/mutation';
import { get_vendor_products_id } from '../../../services/vendor-products/queries';
import {
  IVendorProducts,
  IVendorProductsCreate,
  IVendorProductsCustom
} from '../../../services/vendor-products/types';
import { PictureUpload } from '../pictures';

const { Option } = Select;

const EditVendorProducts = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // This is needed to get the product details update as its written
  const [productDetails, setProductDetails] = useState<any>();
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { id } = useParams();

  useQuery(['vendor-product-details'], async () => {
    const response = await get_vendor_products_id(id as string);

    const values = {
      ...response.data,
      details: JSON.parse(response.data.details),
      visits: JSON.parse(response.data.visits)
    } as IVendorProductsCreate;

    values.visits = values.visits.map((val) => {
      return {
        ...val,
        date: moment(val.date),

        metadata: JSON.parse(val.metadata as string) as IVendorProductsCustom
      };
    });

    if (values.visits) {
      values.visits = values.visits.map((val) => {
        if (typeof val.metadata != 'string') {
          if (val.metadata?.custom) {
            val.metadata.custom = val.metadata.custom.map((customval) => {
              if (customval.date)
                return {
                  ...customval,
                  date: moment(customval.date)
                };
              return customval;
            });
          }
        }
        return {
          ...val
          // date: (val.date as moment.Moment).utc().format()
        };
      });
    } else {
      values.visits = [];
    }
    if (values?.details?.custom) {
      values.details.custom = values.details.custom.map((val) => {
        if (val.date)
          return {
            ...val,
            date: moment(val.date)
          };
        return val;
      });
    }

    form.setFieldsValue(values);

    console.log(values);

    return response;
  });

  const editendorProductsMutation = useMutation(update_vendor_products, {
    onSuccess: (data: any) => {
      message.success('Product edited successfully');
      navigate('/vendor-products');
    },
    onError: (e: any) => {
      message.error(`${e.response.data.message}`, 5);
    }
  });
  const onFinish = async (values: IVendorProductsCreate) => {
    setisLoading(true);

    if (values.visits) {
      values.visits = values.visits.map((val) => {
        if (typeof val.metadata != 'string') {
          if (val.metadata?.custom) {
            val.metadata.custom = val.metadata.custom.map((customval) => {
              if (customval.date)
                return {
                  ...customval,
                  date: (customval.date as moment.Moment).utc().format()
                };

              delete customval.date;
              return customval;
            });
          }
          val.metadata = JSON.stringify(val.metadata);
          // val.pesticides = JSON.stringify(val.pesticides);
          // val.instructions = JSON.stringify(val.instructions);
          // val.remarks = JSON.stringify(val.remarks);
        }
        return {
          ...val
          // date: (val.date as moment.Moment).utc().format()
        };
      });
    } else {
      values.visits = [];
    }

    if (values?.details?.custom) {
      values.details.custom = values.details.custom.map((val) => {
        if (val.date)
          return {
            ...val,
            date: (val.date as moment.Moment).utc().format()
          };

        delete val.date;
        return val;
      });
    }

    const newValues = {
      ...values,
      details: JSON.stringify(values.details)
    } as IVendorProducts;
    await editendorProductsMutation.mutateAsync({ values: newValues, id: id as string });
    setisLoading(false);
  };

  return (
    <AppContent breadcrumbItems={breadcrumbItems}>
      <PageHeader
        title="Product Information"
        style={{
          padding: '8px 0px'
        }}
      />
      <Form
        form={form}
        initialValues={{ visits: [], details: {} }}
        onFinish={onFinish}
        disabled={isLoading}
        layout="vertical"
        validateTrigger={'onChange'}
        onValuesChange={(_, allFields) => {
          setProductDetails(allFields);
        }}
        autoComplete="off">
        <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-5'}>
          <VendorSearch required={true} all={false} />
          <ProductsSearch required={true} formData={{ formName: 'productId' }} noall={true} />
        </div>

        <Collapse>
          <Collapse.Panel header="Details" key="details">
            <Collapse>
              <Collapse.Panel header="Add Images" key="images">
                <PictureUpload form={form} name={['details', 'image']} />
              </Collapse.Panel>
            </Collapse>

            <Form.Item name={['details']} hidden>
              <Input />
            </Form.Item>
            <Collapse>
              <Collapse.Panel header="Add Custom" key="texts">
                <Form.List name={['details', 'custom']}>
                  {(fieldsText, { add: addText, remove: removeText }, { errors: errorsText }) => (
                    <>
                      {fieldsText.map(({ key: keyText, name: nameText, ...restFieldText }) => (
                        <div
                          key={keyText}
                          className="card"
                          style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                          <div className={'grid grid-cols-1 sm:grid-cols-2 gap-x-5'} key={keyText}>
                            <Form.Item {...restFieldText} name={[nameText, 'label']} label="Label">
                              <Input />
                            </Form.Item>
                            <Form.Item {...restFieldText} name={[nameText, 'value']} label="Value">
                              <Input />
                            </Form.Item>
                            <Form.Item {...restFieldText} name={[nameText, 'date']} label="Date">
                              <DatePicker />
                            </Form.Item>

                            {fieldsText.length > 0 ? (
                              <div className="flex items-center justify-start">
                                <CustomButton
                                  backgroundColor="white"
                                  text="Remove"
                                  textColor="red"
                                  marginTop={'20px'}
                                  onClick={() => removeText(nameText)}
                                />
                              </div>
                            ) : null}
                            {/* </div> */}
                          </div>
                        </div>
                      ))}
                      <CustomButton
                        backgroundColor="white"
                        text="Add"
                        textColor="green"
                        marginTop={'20px'}
                        onClick={() =>
                          addText({
                            label: '',
                            value: ''
                          })
                        }
                      />
                    </>
                  )}
                </Form.List>
              </Collapse.Panel>
            </Collapse>
          </Collapse.Panel>
        </Collapse>

        <Collapse>
          <Collapse.Panel header="Visits" key="visits">
            <Form.List name={['visits']}>
              {(fields, { add: add, remove: remove }, { errors: errors }) => (
                <>
                  {fields.map(({ key: key, name: name, ...restField }) => (
                    <div
                      key={key}
                      className="card"
                      style={{ borderRadius: '9px', backgroundColor: 'white' }}>
                      <div
                        className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5'}
                        key={key}>
                        {/* <Form.Item {...restField} name={[name, 'pesticides']} label="Pesticides">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'instructions']}
                          label="Instructions">
                          <Input />
                        </Form.Item>
                        <Form.Item {...restField} name={[name, 'remarks']} label="Remarks">
                          <Input />
                        </Form.Item>*/}
                        <Form.Item {...restField} name={[name, 'date']} label="Date">
                          <DatePicker />
                        </Form.Item>
                        {fields.length > 0 ? (
                          <div className="flex items-center justify-start">
                            <CustomButton
                              backgroundColor="white"
                              text="Remove"
                              textColor="red"
                              marginTop={'20px'}
                              onClick={() => remove(name)}
                            />
                          </div>
                        ) : null}
                      </div>

                      <Form.Item name={[name, 'metadata']} hidden>
                        <Input />
                      </Form.Item>
                      <Collapse>
                        <Collapse.Panel header="Details" key="metadata">
                          <Collapse>
                            <Collapse.Panel header="Add Images" key="images">
                              <PictureUpload
                                form={form}
                                name={['visits', name, 'metadata', 'image']}
                              />
                            </Collapse.Panel>
                          </Collapse>

                          <Collapse>
                            <Collapse.Panel header="Add Custom" key="texts">
                              <Form.List name={[name, 'metadata', 'custom']}>
                                {(
                                  fieldsText,
                                  { add: addText, remove: removeText },
                                  { errors: errorsText }
                                ) => (
                                  <>
                                    {fieldsText.map(
                                      ({ key: keyText, name: nameText, ...restFieldText }) => (
                                        <div
                                          key={keyText}
                                          className="card"
                                          style={{
                                            borderRadius: '9px',
                                            backgroundColor: 'white'
                                          }}>
                                          <div
                                            className={'grid grid-cols-1 sm:grid-cols-2 gap-x-5'}
                                            key={keyText}>
                                            <Form.Item
                                              {...restFieldText}
                                              name={[nameText, 'label']}
                                              label="Label">
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              {...restFieldText}
                                              name={[nameText, 'value']}
                                              label="Value">
                                              <Input />
                                            </Form.Item>
                                            <Form.Item
                                              {...restFieldText}
                                              name={[nameText, 'date']}
                                              label="Date">
                                              <DatePicker />
                                            </Form.Item>

                                            {fieldsText.length > 0 ? (
                                              <div className="flex items-center justify-start">
                                                <CustomButton
                                                  backgroundColor="white"
                                                  text="Remove"
                                                  textColor="red"
                                                  marginTop={'20px'}
                                                  onClick={() => removeText(nameText)}
                                                />
                                              </div>
                                            ) : null}
                                            {/* </div> */}
                                          </div>
                                        </div>
                                      )
                                    )}
                                    <CustomButton
                                      backgroundColor="white"
                                      text="Add"
                                      textColor="green"
                                      marginTop={'20px'}
                                      onClick={() =>
                                        addText({
                                          label: '',
                                          value: ''
                                        })
                                      }
                                    />
                                  </>
                                )}
                              </Form.List>
                            </Collapse.Panel>
                          </Collapse>
                        </Collapse.Panel>
                      </Collapse>

                      {/* </div> */}
                    </div>
                  ))}
                  <CustomButton
                    backgroundColor="white"
                    text="Add"
                    textColor="green"
                    marginTop={'20px'}
                    onClick={() =>
                      add({
                        date: moment(),
                        metadata: ''
                      })
                    }
                  />
                </>
              )}
            </Form.List>
          </Collapse.Panel>
        </Collapse>

        <div className="flex justify-end mt-5">
          <Form.Item>
            <CustomSubmitButton text="Submit" />
          </Form.Item>
        </div>
      </Form>
    </AppContent>
  );
};

export default EditVendorProducts;
const breadcrumbItems = [
  {
    label: 'Vendor Products',
    link: '/vendor-products'
  },
  {
    label: 'Edit'
  }
];
