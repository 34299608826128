import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IVendorProductData, IVendorProductListResponse } from './types';

export const get_vendor_products_list = async (filter = '') => {
  // const response = await http.get(`vendor-products/` + filter);
  const response: AxiosResponse<IVendorProductListResponse, any> = await http.get(
    `vendor-products/?` + filter
  );
  return response;
};
export const get_vendor_products_id = async (id: string | number) => {
  const response: AxiosResponse<IVendorProductData, any> = await http.get(`vendor-products/${id}`);
  return response;
};
