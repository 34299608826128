import { Form, FormInstance, message, PageHeader, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { get_product_list } from '../../../services/products/queries';
import ProductsDB from '../../../store/localstorage/ProductsDB';
interface UsersSearchProps {
  formData?: {
    formName: any;
  };
  onSelect?: (value: number) => any;
  noall?: boolean;
  setProductInformation?: any;
  required?: boolean;
  isMultiple?: boolean;
}
const { Option } = Select;
export const ProductsSearch: React.FC<UsersSearchProps> = ({
  formData,
  onSelect,
  noall = false,
  required = false,
  setProductInformation,
  isMultiple = false
}) => {
  // console.log('noall', noall);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  useEffect(() => {
    searchProductIndexDB(debouncedSearchValue);
  }, [debouncedSearchValue]);
  const [productSearch, setProductSearch] = useState<any>([]);

  const searchProductIndexDB = async (value: string) => {
    try {
      const response = await ProductsDB.searchProduct(value);
      if (!response || response.length == 0) {
        message.error('Cannot find any product with that name in cache!');
        searchUser(value);
      } else {
        setProductSearch(response);
      }
    } catch (error) {
      message.error(error as string);
    }
  };

  const searchUser = async (value: any) => {
    let response;
    try {
      response = await get_product_list(0, 5000, value);
    } catch (e) {
      console.log(e);
    }
    if (!response || response.data.results.length == 0) {
      message.error('Cannot find any product with that name!');
      setProductSearch([]);
    } else {
      setProductSearch(response.data.results);
      ProductsDB.addProducts(response.data.results);
    }
  };

  const productInfromation = (val: number) => {
    const selectedProduct = productSearch.find((curr: any) => curr.id == val);
    setProductInformation(selectedProduct);
  };

  const options = productSearch
    .filter((val: any) => !val.hidden && val.status.some((el: any) => !el.hidden))
    .map((d: any) => (
      <Option key={d.id} value={d.id}>
        {d.name}
      </Option>
    ));

  return (
    <>
      {formData ? (
        <Form.Item
          name={formData.formName}
          label="Product"
          rules={[
            {
              required: required,
              message: 'Please choose a product!'
            }
          ]}>
          <Select
            showSearch
            mode={isMultiple ? 'multiple' : undefined}
            allowClear
            placeholder={'search product'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            dropdownMatchSelectWidth={false}
            onSearch={(val) => {
              setSearchValue(val);
            }}
            onSelect={(val: number) => {
              // console.log(val);
              if (onSelect) onSelect(val);
              if (setProductInformation) productInfromation(val);
              //   const selectedProduct = productSearch.find((value) => value.id == val);
            }}
            notFoundContent={null}>
            {noall ? <></> : <Option value="">All</Option>}
            {options}
          </Select>
        </Form.Item>
      ) : (
        <Select
          showSearch
          mode={isMultiple ? 'multiple' : undefined}
          allowClear
          placeholder={'search product'}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          dropdownMatchSelectWidth={false}
          onSearch={(val) => {
            setSearchValue(val);
          }}
          onSelect={(val: number) => {
            // console.log(val);
            if (onSelect) onSelect(val);
            if (setProductInformation) productInfromation(val);
            //   const selectedProduct = productSearch.find((value) => value.id == val);
          }}
          notFoundContent={null}>
          {noall ? <></> : <Option value="">All</Option>}

          {options}
        </Select>
      )}
    </>
  );
};
