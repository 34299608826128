import { AxiosResponse } from 'axios';
import http from '../../utils/http.utils';
import { IVendorProductData, IVendorProducts } from './types';

export const create_vendor_products = async (values: IVendorProducts) => {
  const response: AxiosResponse<IVendorProductData, any> = await http.store(
    'vendor-products/',
    values
  );
  return response;
};

export const update_vendor_products = async (information: {
  values: IVendorProducts;
  id: string | number;
}) => {
  const response: AxiosResponse<IVendorProductData, any> = await http.update(
    `vendor-products/${information.id}`,
    information.values
  );
  return response;
};

export const delete_vendor_products = async (id: string | number) => {
  const response: AxiosResponse<any, any> = await http.remove('vendor-products/' + id);
  return response;
};
