import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, FormInstance, Modal, PageHeader, Spin, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { API_URL, JWT_TOKEN } from '../../../constants/config';
import { getLocalStorage } from '../../../utils/storage.utils';
import { get_image } from '../../../components/Common/FetchImage/FetchImage';
import { randomUUID } from 'crypto';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface PictureUploadProps {
  form: FormInstance<any>;
  name: any[];
}
export const PictureUpload: React.FC<PictureUploadProps> = ({ form, name }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ file, fileList: newFileList }) => {
    const updatedFileId = file?.response?.id;
    setFileList(newFileList);

    console.log(name);

    if (updatedFileId) {
      const val = form.getFieldValue(name);
      if (file.status == 'removed') {
        form.setFieldValue(name, [...val.filter((value: number) => value != updatedFileId)]);
      } else {
        if (!val) {
          form.setFieldValue(name, [updatedFileId]);
        } else {
          form.setFieldValue(name, [...val, updatedFileId]);
        }
      }
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const UploadProps: UploadProps = {
    name: 'media',
    action: `${API_URL}storage/upload`,
    headers: {
      Authorization: `Bearer ${getLocalStorage(JWT_TOKEN)}`
    },
    listType: 'picture-card',
    fileList: fileList,
    accept: 'image/*',
    onPreview: handlePreview,
    onChange: handleChange
  };
  useEffect(() => {
    loadImages();
  }, []);

  const loadImages = async () => {
    const imageIds = form.getFieldValue(name);
    if (imageIds) {
      setLoad(true);
      const data: UploadFile[] = [];
      for (let i = 0; i < imageIds.length; i++) {
        const imgData = await get_image(`${API_URL}storage/media/${imageIds[i]}`);
        data.push({
          url: imgData,
          status: 'done',
          name: 'aaaa.png',
          uid: JSON.stringify(name) + i
        });
      }
      setFileList(data);
    }
    setLoad(false);
  };

  return (
    <>
      <Spin spinning={load}>
        <PageHeader
          subTitle="Add images"
          style={{
            padding: '8px 0px'
          }}
        />
        <Upload {...UploadProps}>{fileList.length >= 8 ? null : uploadButton}</Upload>
        <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Spin>
    </>
  );
};
